// TODO: add encrypt/decrypt stuff
export const localStorageEffect =
  (key, ttl = 60 * 60 * 1000) =>
  ({ setSelf, onSet }) => {
    if (typeof localStorage === "undefined") {
      return;
    }

    const ttlKey = `${key}-ttl`;
    const savedValue = localStorage.getItem(key);
    const savedTimestamp = Number(localStorage.getItem(ttlKey));

    if (savedValue != null) {
      try {
        if (+new Date() - savedTimestamp > ttl) {
          localStorage.removeItem(key);
          localStorage.removeItem(ttlKey);
        } else {
          setSelf(JSON.parse(savedValue));
        }
      } catch (e) {
        console.log(e);
      }
    }

    onSet((newValue, _, isReset) => {
      if (typeof localStorage === "undefined") {
        return;
      }
      if (isReset) {
        localStorage.removeItem(key);
        localStorage.removeItem(ttlKey);
        return;
      }
      localStorage.setItem(key, JSON.stringify(newValue));
      localStorage.setItem(ttlKey, `${+new Date()}`);
    });
  };
